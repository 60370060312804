import * as amplitude from "@amplitude/analytics-browser";
import { useQuery } from "@tanstack/react-query";
import { ReactComponent as LockIcon } from "assets/icons/lock-closed.svg";
import ScrollToTopButton from "components/ScrollToTopButton";
import { useCallback, useEffect, useState } from "react";
import { useUser } from "../UserContext";
import GoodsList from "../components/GoodsList"; // Import the new component
import GoodsNav from "../components/GoodsNav";
import UserInfo from "../components/UserInfo";
import {
  getOwnedGoods,
  getUserCollectionsOffset,
  getUserGoods,
  getUserInfo,
} from "../services/api";

function Goods({ selectedTab, userLinkTag }) {
  // console.log("Goods", selectedTab, userLinkTag);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const { user: currentUser } = useUser();

  const setSelectedCategoryAnalytics = (category) => {
    amplitude.track("filters_applied", { category });
    setSelectedCategory(category);
  };

  useEffect(() => {
    setSelectedCategory(0);
  }, [userLinkTag]);

  useEffect(() => {
    if (userLinkTag === currentUser?.link_tag) {
      amplitude.track("mygoods_opened");
    } else {
      amplitude.track("profile_opened", { link_tag: userLinkTag });
    }
  }, []);

  const {
    data: userByLinkTag,
    isLoading: isLoadingUserByLinkTag,
    isError: isErrorUserByLinkTag,
    isIdle: isIdleUserByLinkTag,
  } = useQuery({
    queryKey: ["userInfo", userLinkTag],
    queryFn: () => getUserInfo(userLinkTag),
  });

  const fetchGoods = useCallback(
    ({ pageParam = null }) => {
      // console.log("fetchGoods", userByLinkTag?.id, selectedCategory, pageParam);
      if (selectedTab === "goods")
        return getUserGoods(userByLinkTag?.id, selectedCategory, pageParam);
      else if (selectedTab === "collections")
        return getUserCollectionsOffset(userByLinkTag?.id, pageParam);
      else if (selectedTab === "owned")
        return getOwnedGoods(userByLinkTag?.id, pageParam);
    },
    [userByLinkTag, selectedCategory, selectedTab]
  );

  // console.log("userByLinkTag", userByLinkTag, currentUser);
  if (!isLoadingUserByLinkTag && !userByLinkTag) {
    return <div>User not found</div>;
  }

  return (
    <div
      className="p-3 overflow-x-hidden md:pt-3"
      style={{ paddingTop: "env(safe-area-inset-top)" }}
    >
      <UserInfo
        className="mt-8 mb-20"
        user={userByLinkTag}
        isMyPage={userLinkTag === currentUser?.link_tag}
        isOnboarding={
          userByLinkTag?.privacy === 2 && userLinkTag !== currentUser?.link_tag
        }
      />
      {userByLinkTag?.privacy === 2 &&
      userLinkTag !== currentUser?.link_tag &&
      [5, 10, 12].indexOf(currentUser?.id) === -1 ? (
        <div className="flex flex-col items-center text-center text-grey-2 text-sm mt-36">
          <LockIcon className="w-8 h-8 mb-1" />
          This account is private
        </div>
      ) : (
        <>
          {/* <Categories
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          /> */}
          <GoodsNav
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategoryAnalytics}
            selectedTab={selectedTab}
            userLinkTag={userLinkTag}
            // setSelectedTab={setSelectedTabAnalytics}
          />
          {userByLinkTag?.privacy === 2 &&
            [5, 10, 12].indexOf(currentUser?.id) !== -1 && (
              <div className="flex flex-col items-center text-center text-grey-2 text-sm mt-4 mb-4">
                <LockIcon className="w-8 h-8 mb-1" />
                This account is private, but you can still view it
              </div>
            )}
          <GoodsList
            userId={userByLinkTag?.id}
            selectedCategory={selectedCategory}
            fetchGoods={fetchGoods}
            pageType="user"
            queryName={
              "user" +
              selectedTab.charAt(0).toUpperCase() +
              selectedTab.slice(1)
            }
            userByLinkTag={userByLinkTag}
          />
          <ScrollToTopButton />
        </>
      )}
    </div>
  );
}

export default Goods;
