import { useUser } from "UserContext";
import { ReactComponent as GoodsIcon } from "assets/icons/goods.svg";
import { ReactComponent as CareIcon } from "assets/icons/heart.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as ExploreIcon } from "assets/icons/users.svg";
import { NavLink, useLocation } from "react-router-dom";

const NavButtons = () => {
  const { user, isLoading } = useUser();
  const location = useLocation();
  const goodsActive = location.pathname === `/${user?.link_tag}`;
  if (
    location.pathname.includes("/intro/") ||
    location.pathname.includes("/settings") ||
    location.pathname.includes("/good/") ||
    location.pathname.includes("/last-step") ||
    isLoading
  ) {
    return null;
  } // dont show nav buttons on onboarding page

  return (
    <nav
      style={{ paddingBottom: "env(safe-area-inset-bottom)" }}
      className="z-10 fixed bottom-0 bg-white flex justify-between shadow-[0_1px_12px_0px_rgba(0,0,0,0.1)] left-1/2 -translate-x-1/2  w-full sm:w-min sm:rounded-full sm:bottom-6 align-baseline text-lg"
    >
      <NavLink
        to={`/${user?.link_tag || ""}`}
        className={({ isActive }) =>
          [
            "flex flex-col items-center pb-3 pt-2 pl-11 pr-3 w-full text-[#808080] active:scale-[0.9875]",
            "hover:text-primary",
            isActive ? "text-primary" : "",
          ].join(" ")
        }
      >
        <GoodsIcon className="w-6 h-6" />
        Goods
      </NavLink>
      <NavLink
        to="/explore"
        className={({ isActive }) =>
          [
            "flex flex-col items-center px-3 pb-3 pt-2 w-full text-[#808080] active:scale-[0.9875]",
            "hover:text-primary",
            isActive ? "text-primary" : "",
          ].join(" ")
        }
      >
        <ExploreIcon className="w-6 h-6" />
        Explore
      </NavLink>
      <NavLink
        to="/search"
        className={({ isActive }) =>
          [
            "flex flex-col items-center px-3 pb-3 pt-2 w-full text-[#808080] active:scale-[0.9875]",
            "hover:text-primary",
            isActive ? "text-primary" : "",
          ].join(" ")
        }
      >
        <SearchIcon className="w-6 h-6" />
        Search
      </NavLink>
      <NavLink
        to="/care"
        className={({ isActive }) =>
          [
            "flex flex-col items-center pl-3 pb-3 pt-2 pr-11 w-full text-[#808080] active:scale-[0.9875]",
            "hover:text-primary",
            isActive ? "text-primary" : "",
          ].join(" ")
        }
      >
        <CareIcon className="w-6 h-6" />
        Care
      </NavLink>
    </nav>
  );
};

export default NavButtons;
