import { useMutation, useQueryClient } from "@tanstack/react-query";
import { archiveGood } from "services/api";

export const useArchiveMutation = (userId, selectedCategory) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (goodId) => archiveGood(goodId),
    onMutate: async (goodId) => {
      await queryClient.cancelQueries({
        queryKey: ["userGoods", userId, selectedCategory, false],
      });

      const previousGoods = queryClient.getQueryData([
        "userGoods",
        userId,
        selectedCategory,
        false,
      ]);

      // Check if the userGoods data exists
      if (previousGoods) {
        queryClient.setQueryData(
          ["userGoods", userId, selectedCategory, false],
          (old) => {
            return {
              pages: old?.pages.map((page) => ({
                ...page,
                goods: page.goods.filter((good) => good.id !== goodId),
              })),
              pageParams: old?.pageParams,
            };
          }
        );
      }

      return { previousGoods, goodId };
    },
    onError: (err, variables, context) => {
      // Only reset if previousGoods is available
      if (context?.previousGoods) {
        queryClient.setQueryData(
          ["userGoods", userId, selectedCategory, false],
          context.previousGoods
        );
      }
    },
    onSettled: ({ context }) => {
      queryClient.invalidateQueries({
        queryKey: ["userGoods", userId, selectedCategory, false],
      });
      queryClient.invalidateQueries({
        queryKey: ["archiveGoods", userId],
      });
      queryClient.invalidateQueries({
        queryKey: ["collectionGoods"],
      });
      queryClient.invalidateQueries({
        queryKey: ["userCollections", userId],
      });
      // Invalidate "goodDetails" only if goodId is available
      if (context?.goodId) {
        queryClient.invalidateQueries({
          queryKey: ["goodDetails", context.goodId],
        });
      }
    },
  });
};
