import * as amplitude from "@amplitude/analytics-browser";
import { ReactComponent as AppleIcon } from "assets/icons/apple.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close_filled.svg";
import { ReactComponent as InstaIcon } from "assets/icons/insta.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as ShuffleIcon } from "assets/icons/shuffle.svg";
import { ReactComponent as Spinner } from "assets/icons/spinner.svg";
import PrimaryButton from "components/PrimaryButton";
import useShare from "hooks/useShare";
import { useEffect, useState } from "react";
import { API_URL } from "services/api";
import { useToast } from "ToastContext";
import { useUser } from "UserContext";
import BottomModal from "../bottom_modal/BottomModal";

const ShareModal = ({ isOpen, onClose, user }) => {
  const onShare = useShare();
  const { isWebView } = useUser();
  const { addToast } = useToast();
  const [shuffle, setShuffle] = useState(0); // State to track shuffle count
  const [imageId, setImageId] = useState(1);
  const [loadingShuffle, setLoadingShuffle] = useState(false);
  const [loadingShare, setLoadingShare] = useState(false);
  const [stickerBase64, setStickerBase64] = useState(null); // State for base64 sticker
  const [backgroundImage, setBackgroundImage] = useState(
    `https://goodsend.fra1.cdn.digitaloceanspaces.com/other/background_${imageId}.webp`
  );

  const [isAppRequiredOpen, setIsAppRequiredOpen] = useState(false);
  const [isUpdateAppOpen, setisUpdateAppOpen] = useState(false);

  const onCloseAppRequired = () => {
    setIsAppRequiredOpen(false);
    setisUpdateAppOpen(false);
  };

  const downloadImageAsBase64 = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result); // Resolve with the Base64 string
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error downloading the image:", error);
      throw error;
    }
  };

  const handleShareClick = (url) => {
    amplitude.track("share_modal_link_copied");
    navigator.clipboard
      .writeText(url ? url : window.location.href)
      .then(() => addToast("Link copied to clipboard"))
      .catch((error) => console.error("Error copying content:", error));
  };

  // Fetch base64-encoded image
  const fetchBase64Image = async (url) => {
    const response = await fetch(url);
    const data = await response.json();
    return `data:image/png;base64,${data.image_base64}`;
  };

  const preloadImage = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = resolve;
    });
  };

  // Function to load images based on provided imageId and shuffle values
  const loadImages = async (imageId, shuffleValue) => {
    const backgroundImageUrl = `https://goodsend.fra1.cdn.digitaloceanspaces.com/other/background_${imageId}.webp`;
    const stickerImageUrl = `${API_URL}/meta/insta/sticker/${user?.id}?shuffle=${shuffleValue}&background_id=${imageId}`;

    try {
      const [backgroundBase64Image, stickerBase64Image] = await Promise.all([
        preloadImage(backgroundImageUrl),
        fetchBase64Image(stickerImageUrl),
      ]);

      // Update state with loaded base64 images
      //   setBackgroundBase64(backgroundBase64Image);
      setBackgroundImage(backgroundImageUrl);
      setStickerBase64(stickerBase64Image);
    } catch (error) {
      console.error("Error loading images:", error);
    }
  };

  // Load images on component mount or when modal is opened
  useEffect(() => {
    loadImages(imageId, shuffle);
  }, [user]);

  useEffect(() => {
    if (isOpen) {
      amplitude.track("share_modal_opened");
    }
  }, [isOpen]);

  const onInstaShare = async () => {
    amplitude.track("share_modal_insta_share_tapped");
    if (
      window.webkit === undefined ||
      window.webkit.messageHandlers === undefined ||
      window.webkit.messageHandlers.shareToInstagram === undefined
    ) {
      if (isWebView) {
        amplitude.track("share_modal_insta_share_failed", {
          reason: "old_app_version",
        });
        setisUpdateAppOpen(true);
      } else {
        amplitude.track("share_modal_insta_share_failed", {
          reason: "not_webview",
        });
        setIsAppRequiredOpen(true);
      }
      return;
    }

    setLoadingShare(true);

    const backgroundImageWStickerUrl = `https://goodsend.fra1.cdn.digitaloceanspaces.com/other/background_${imageId}_w_sticker.webp`;

    try {
      const backgroundImageBase64 = await downloadImageAsBase64(
        backgroundImageWStickerUrl
      );

      window.webkit.messageHandlers.shareToInstagram.postMessage({
        backgroundImage: backgroundImageBase64,
        stickerImage: stickerBase64, // Assuming stickerBase64 is already set
      });
    } catch (error) {
      addToast("Error sharing to Instagram :(");
      amplitude.track("share_modal_insta_share_failed", {
        reason: error.message,
      });
      //   console.error("Error sharing to Instagram:", error);
    }
    amplitude.track("share_modal_insta_share_success");
    setLoadingShare(false);
  };

  const handleShuffle = async () => {
    amplitude.track("share_modal_shuffle_tapped");
    setLoadingShuffle(true);
    const newImageId = imageId === 7 ? 1 : imageId + 1;
    const newShuffle = Date.now();

    // Load new shuffled images
    await loadImages(newImageId, newShuffle);

    setImageId(newImageId);
    setShuffle(newShuffle);
    setLoadingShuffle(false);
  };

  return (
    <BottomModal
      isOpen={isOpen}
      onClose={onClose}
      zIndex={11}
      containerClassName={"max-w-[550px] bg-[#000] rounded-none"}
      isFullHeight={true}
      noTopPadding={true}
      className={"max-h-[100vh] h-full rounded-none md:rounded-t-3xl"}
      isNested={true}
    >
      <div
        className="flex flex-col h-full items-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          paddingBottom: "env(safe-area-inset-bottom)",
          paddingTop: "env(safe-area-inset-top)",
        }}
      >
        <div className="relative mt-4 pt-16 pb-10 rounded-[32px] flex-grow flex-shrink-0 px-6 w-full max-w-[440px]">
          <CloseIcon
            onClick={onClose}
            className="absolute top-4 left-4 w-8 h-8 text-white"
          />
          <div
            className="h-full w-full"
            style={{
              backgroundImage: `url(${stickerBase64})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
        </div>

        <div className="flex w-full justify-around mt-6 text-white flex-shrink-0 py-4">
          <button
            className="text-sm/tight hover:filter hover:brightness-95 active:scale-[0.9875] drop-shadow-2xl drop-shadow-black"
            onClick={onInstaShare}
          >
            <div className="flex items-center justify-center w-14 h-14 bg-white rounded-full mb-1">
              {loadingShare ? (
                <Spinner className="w-6 h-6 text-primary animate-spin" />
              ) : (
                <InstaIcon className="w-6 h-6 text-primary" />
              )}
            </div>
            Share to
            <br />
            Stories
          </button>

          <button
            className="text-sm/tight hover:filter hover:brightness-95 active:scale-[0.9875] drop-shadow-2xl"
            onClick={() => handleShareClick(window.location.href)}
          >
            <div className="flex items-center justify-center w-14 h-14 bg-white rounded-full mb-1">
              <LinkIcon className="w-8 h-8 text-primary" />
            </div>
            Copy
            <br />
            Link
          </button>

          <button
            className="text-sm/tight hover:filter hover:brightness-95 active:scale-[0.9875] drop-shadow-2xl"
            onClick={handleShuffle}
          >
            <div className="flex items-center justify-center w-14 h-14 bg-white rounded-full mb-1">
              {loadingShuffle ? (
                <Spinner className="w-6 h-6 text-primary animate-spin" />
              ) : (
                <ShuffleIcon className="w-6 h-6 text-primary" />
              )}
            </div>
            Shuffle
            <br />
            Image
          </button>

          <button
            className="text-sm/tight hover:filter hover:brightness-95 active:scale-[0.9875] drop-shadow-2xl"
            onClick={() => {
              onShare();
              amplitude.track("share_modal_more_options_tapped");
            }}
          >
            <div className="flex items-center justify-center w-14 h-14 bg-white rounded-full mb-1">
              <div className="flex space-x-1">
                <span className="w-1.5 h-1.5 bg-primary rounded-full"></span>
                <span className="w-1.5 h-1.5 bg-primary rounded-full"></span>
                <span className="w-1.5 h-1.5 bg-primary rounded-full"></span>
              </div>
            </div>
            More
            <br />
            Options
          </button>
        </div>
      </div>
      <BottomModal
        isOpen={isAppRequiredOpen || isUpdateAppOpen}
        onClose={onCloseAppRequired}
        zIndex={40}
        containerClassName={"max-w-[550px]"}
        isNested={true}
        isCloseIcon={false}
      >
        <div className="px-4 pb-6 h-full pt-8">
          <div className="flex flex-col items-center justify-center bg-grey-4 rounded-3xl w-full text-sm text-grey-1 text-center py-4">
            <div className="flex justify-center items-center bg-white rounded-3xl font-black text-6xl w-[100px] h-[100px] mb-2">
              G
            </div>
            Goodsend
          </div>
          <div className="flex flex-col justify-center items-center text-center px-4">
            <h2 className="text-xl font-bold mt-8 mb-4 max-w-[300px]">
              {isAppRequiredOpen
                ? "Goodsend iOS App Required"
                : "Please update Goodsend app to use new features"}
            </h2>
            <p className="text-lg text-grey-1 max-w-[350px]">
              {isAppRequiredOpen
                ? "Unlock full functionality by downloading the latest Goodsend app."
                : "For new enhancements, update your Goodsend app via the App Store. Tap 'Update now' to proceed."}
            </p>
          </div>
          <a href="https://apps.apple.com/app/id6449746781">
            <PrimaryButton className={"w-full mt-8 flex items-center"}>
              <AppleIcon className="w-6 h-6 mr-1" />
              {isAppRequiredOpen ? "Download the app" : "Update now"}
            </PrimaryButton>
          </a>
        </div>
      </BottomModal>
    </BottomModal>
  );
};

export default ShareModal;
