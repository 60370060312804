import { useMutation, useQueryClient } from "@tanstack/react-query";
import { restoreGood } from "services/api";

export const useRestoreMutation = (userId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (goodId) => restoreGood(goodId),
    onMutate: async (goodId) => {
      await queryClient.cancelQueries({
        queryKey: ["archivedGoods"],
      });

      const previousGoods = queryClient.getQueryData(["archivedGoods"]);

      if (previousGoods) {
        queryClient.setQueryData(["archivedGoods"], (old) => {
          return {
            pages: old?.pages.map((page) => ({
              ...page,
              goods: page.goods.filter((good) => good.id !== goodId),
            })),
            pageParams: old?.pageParams,
          };
        });
      }

      return { previousGoods, goodId };
    },
    onError: (err, variables, context) => {
      // Only reset if previousGoods is available
      if (context?.previousGoods) {
        queryClient.setQueryData(["archivedGoods"], context.previousGoods);
      }
    },
    onSettled: ({ context }) => {
      queryClient.invalidateQueries({
        queryKey: ["userGoods", userId],
      });
      queryClient.invalidateQueries({
        queryKey: ["archivedGoods"],
      });
      // Invalidate "goodDetails" only if goodId is available
      if (context?.goodId) {
        queryClient.invalidateQueries({
          queryKey: ["goodDetails", context.goodId],
        });
      }
    },
  });
};
