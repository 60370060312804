import { useInfiniteQuery } from "@tanstack/react-query";
import { useGoodModal } from "GoodModalContext";
import { useUser } from "UserContext";
import { ReactComponent as LeftArrow } from "assets/icons/left_arrow.svg";
import GoodsGrid from "components/GoodsGrid";
import { useInView } from "react-intersection-observer";
import { getArchivedGoods } from "services/api";
import BottomModal from "../bottom_modal/BottomModal";

function ArchivedModal({ isOpen, onClose }) {
  const { user: currentUser } = useUser();
  const { openGoodModal } = useGoodModal();

  const {
    data: goods,
    isPending,
    isFetching,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["archivedGoods"],
    queryFn: ({ pageParam = 0 }) => getArchivedGoods(pageParam),
    getNextPageParam: (lastPage, pages) => lastPage?.offset || null,
    enabled: isOpen,
  });

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "0px 0px 500px 0px",
  });

  if (inView && hasNextPage && !isPending && !isFetching) {
    fetchNextPage();
  }

  return (
    <BottomModal
      isOpen={isOpen}
      onClose={onClose}
      zIndex={11}
      containerClassName={"max-w-[550px]"}
      isFullHeight={true}
      className={"max-h-[100vh] h-full"}
      isNested={true}
    >
      <div className="flex flex-col px-3 min-h-full">
        <div className="relative flex justify-between align-center mb-8 p-2 mt-4 md:mt-6">
          <LeftArrow
            onClick={onClose}
            className="absolute bottom-2 w-6 h-6 text-black flex-grow-0"
          />
          <h3 className="text-lg font-bold flex-grow text-center">
            Goods Archive
          </h3>
        </div>
        {!isLoading && !goods?.pages[0]?.goods?.length ? (
          <div className="flex-grow flex justify-center items-center">
            <p className="text-center text-grey-2">
              You don't have any archived goods.
            </p>
          </div>
        ) : (
          <div className="pb-[100px]">
            <GoodsGrid
              showSkeleton={isLoading || !goods}
              showOwner={false}
              ref={ref}
              isLoadingNextPage={isFetchingNextPage || hasNextPage}
              openGoodModal={(goodId) => {
                openGoodModal(goodId, 0, currentUser?.id, "archive");
              }}
              goods={goods}
            />
            <div ref={ref} />
          </div>
        )}
      </div>
    </BottomModal>
  );
}

export default ArchivedModal;
