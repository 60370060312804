import * as amplitude from "@amplitude/analytics-browser";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { usePaywall } from "PaywallContext";
import { useUser } from "UserContext";
import { ReactComponent as ArchiveIcon } from "assets/icons/archive.svg";
import { ReactComponent as DocIcon } from "assets/icons/doc.svg";
import { ReactComponent as ExclamationIcon } from "assets/icons/exclamation.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock-closed.svg";
import { ReactComponent as SignOutIcon } from "assets/icons/signout.svg";
import { ReactComponent as TelegramIcon } from "assets/icons/telegram.svg";
import TelegramButton from "components/TelegramButton";
import { useTelegramConnectMutation } from "hooks/useTelegramConnectMutation";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deactivateAccount, getSettings } from "services/api";
import BottomModal from "../bottom_modal/BottomModal";
import ArchivedModal from "./ArchivedModal";
import DeactivateModal from "./DeactivateModal";
import PersonalInfoModal from "./PersonalInfoModal";
import PrivacySettingsModal from "./PrivacySettingsModal";

const SettingsModal = ({ isOpen, onClose }) => {
  const { openPaywall } = usePaywall();
  const { user: currentUser, handleSignOut: signOut } = useUser();
  const navigate = useNavigate();

  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const [isPersonalInfoOpen, setIsPersonalInfoOpen] = useState(false);
  const [isPrivacySettingsOpen, setIsPrivacySettingsOpen] = useState(false);
  const [isArchivedOpen, setIsArchivedOpen] = useState(false);
  const queryClient = useQueryClient();

  const onSignOutClick = () => {
    signOut();
    navigate("/intro");
  };

  const { mutate: deactivate } = useMutation({
    mutationFn: deactivateAccount,
    onSuccess: () => {
      signOut();
      navigate("/intro");
    },
  });

  const { data: settings } = useQuery({
    queryFn: getSettings,
    queryKey: ["userSettings"],
  });

  const { mutate: handleConnectTelegram } = useTelegramConnectMutation();

  const onArchivedClick = () => {
    if (!currentUser?.is_premium) openPaywall("archive", "settings");
    else setIsArchivedOpen(true);
  };

  return (
    <>
      <BottomModal
        isOpen={isOpen}
        onClose={onClose}
        containerClassName={" max-w-[550px]"}
        zIndex={10}
        className={"pb-[100px] md:pb-4"}
      >
        <div className="flex flex-col px-4 min-h-full">
          <h2 className="text-black text-xl font-bold mb-6">Settings</h2>
          <p className="text-sm max-w-[330px] text-grey-2 mb-7">
            Goodsend - Curate, Explore, and Showcase Your Favourite Beautiful
            Objects. All in One Place. <br />
            v0.03, Created by{" "}
            <a
              href="https://instagram.com/lizksn"
              className="text-primary underline"
            >
              Liz
            </a>
            ,{" "}
            <a
              href="https://instagram.com/xkasianx"
              className="text-primary underline"
            >
              Illia
            </a>{" "}
            &{" "}
            <a
              href="https://instagram.com/serhiimatchuk"
              className="text-primary underline"
            >
              Serhii
            </a>
          </p>

          {!currentUser?.is_premium && (
            <div
              onClick={() => {
                openPaywall("products", "settings_banner");
              }}
              className="text-sm  flex justify-between items-center w-full p-4  bg-primary rounded-2xl text-white mb-3"
            >
              <p className="mr-4 leading-tight text-left max-w-[250px]">
                Join <b>Goodsend Premium</b> to unlock awesome premium features.
              </p>
              <button className="text-primary bg-white p-2 leading-none rounded-full hover:filter hover:brightness-95">
                Join
              </button>
            </div>
          )}
          {!settings?.telegram_connected && (
            <TelegramButton
              className="mb-3"
              handleAuth={handleConnectTelegram}
              context="settings"
            >
              <TelegramIcon className="inline-block w-6 h-6 mr-1 text-grey-2" />
              Connect Telegram
            </TelegramButton>
          )}
          <Link
            to="/intro"
            className="flex items-center w-full bg-grey-4 rounded-full text-lg text-black py-4 px-4 mb-3 hover:filter hover:brightness-95"
            onClick={() => amplitude.track("how_to_use_clicked")}
          >
            <ExclamationIcon className="inline-block w-6 h-6 mr-2 text-grey-2 rotate-180" />
            How to use Goodsend?
          </Link>
          <button
            onClick={() => setIsPersonalInfoOpen(true)}
            className="flex items-center w-full bg-grey-4 rounded-full text-lg text-black py-4 px-4 mb-3 hover:filter hover:brightness-95"
          >
            <InfoIcon className="inline-block w-6 h-6 mr-2 text-grey-2" />
            Personal Information
          </button>
          <PersonalInfoModal
            isOpen={isPersonalInfoOpen}
            onClose={() => setIsPersonalInfoOpen(false)}
          />
          <button
            onClick={() => setIsPrivacySettingsOpen(true)}
            className="flex items-center w-full bg-grey-4 rounded-full text-lg text-black py-4 px-4 mb-3 hover:filter hover:brightness-95"
          >
            <LockIcon className="inline-block w-6 h-6 mr-2 text-grey-2" />
            Privacy Settings
          </button>
          <PrivacySettingsModal
            isOpen={isPrivacySettingsOpen}
            onClose={() => setIsPrivacySettingsOpen(false)}
          />
          <button
            onClick={onArchivedClick}
            className="flex items-center w-full bg-grey-4 rounded-full text-lg text-black py-4 px-4 mb-3 hover:filter hover:brightness-95"
          >
            <ArchiveIcon className="inline-block w-6 h-6 mr-2 text-grey-2" />
            Goods Archive
          </button>
          <ArchivedModal
            isOpen={isArchivedOpen}
            onClose={() => setIsArchivedOpen(false)}
          />
          <a
            className="flex items-center w-full bg-grey-4 rounded-full text-lg py-4 px-4 mb-3 hover:filter hover:brightness-95"
            href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
          >
            <DocIcon className="inline-block w-6 h-6 mr-2 text-grey-2" />
            Terms Of Use
          </a>
          <button
            onClick={onSignOutClick}
            className="flex items-center w-full bg-grey-4 rounded-full text-lg py-4 px-4 mb-12 hover:filter hover:brightness-95"
          >
            <SignOutIcon className="inline-block w-6 h-6 mr-2" />
            Sign Out
          </button>
          <button
            onClick={() => setIsDeactivateModalOpen(true)}
            className="flex items-center w-full bg-grey-4 rounded-full text-lg text-black py-4 px-4 mb-3 hover:filter hover:brightness-95"
          >
            <ExclamationIcon className="inline-block w-6 h-6 mr-2 text-grey-2" />
            Deactivate Account
          </button>
        </div>
      </BottomModal>
      <DeactivateModal
        isOpen={isDeactivateModalOpen}
        onClose={() => setIsDeactivateModalOpen(false)}
        onDeactivate={() => deactivate()}
      />
    </>
  );
};

export default SettingsModal;
